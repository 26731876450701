/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-07 12:54:12
 * @Module: 内部客户详情
 */
 <template>
  <div class="CustomerManagementDetail">
    <!-- <div class="title">
      <div class="moving" @click="$router.go(-1)">
        <i class="el-icon-arrow-left"></i>
        返回
      </div>
      <div>
        <el-button type="text" @click="revision" v-if="user.role==='ADMIN'">编辑公司</el-button>
      </div>
    </div> -->
    <div ref="wrap">
      <el-row :gutter="24">
        <el-col :span="18">
          <div class="left1">
            <div class="title">
              <div class="primary">{{data.name}}</div>
              <el-button type="primary" @click="revision" size="small" v-if="['ADMIN','SUPER','LEGAL'].includes(user.role)">编辑修改</el-button>
            </div>
            <div class="content">
              <el-row>
                <el-col :span="16">
                  <div class="content-l">
                    <el-descriptions class="margin-top" :column="2" direction="horizontal">
                      <el-descriptions-item label="签约时间">
                        <span v-if="data.signedAt">{{data.signedAt|timeFormat}}</span>
                        <span v-else>--</span>
                      </el-descriptions-item>
                      <el-descriptions-item label="对接法务">{{data.legalName||'--'}}</el-descriptions-item>

                      <el-descriptions-item label="合同到期时间">
                        <span v-if="data.expiredAt">{{data.expiredAt|timeFormat}}</span>
                        <span v-else>--</span>
                      </el-descriptions-item>
                      <el-descriptions-item label="对接群名">{{data.groupName||'--'}}</el-descriptions-item>
                      <el-descriptions-item label="谁签约的">{{data.signerName||'--'}}</el-descriptions-item>
                      <el-descriptions-item label="分账明细">
                        <!-- <template>
                          <el-popover placement="top" v-if="scope.row.partners.length" trigger="hover">
                            <div v-for="item in scope.row.partners" :key="item.uuid" style="line-height:24px;">
                              <p>{{item.accountName }} - <span class="primary">{{item.ratio}}%</span> </p>
                            </div>

                            <p slot="reference" class="pointer keep1lines">{{scope.row.partners[0].accountName}}- <span class="primary">{{scope.row.partners[0].ratio}}%</span><span v-if="scope.row.partners.length>1">...</span></p>
                          </el-popover>
                          <span v-else>--</span>
                        </template> -->

                        <template>
                          <el-popover placement="top" v-if="data.partners.length" trigger="hover">
                            <div v-for="item in data.partners" :key="item.uuid" style="line-height:24px;">
                              <p>{{item.accountName }} - <span class="primary">{{item.ratio}}%</span> </p>
                            </div>
                            <div v-if="data.partners.length" slot="reference" class="keep1lines primary pointer">
                              <span v-for="(item,index) in data.partners" :key="item.uuid">{{item.accountName}}-{{item.ratio}}% <span v-if="(index+1) < data.partners.length">、</span></span>
                            </div>
                          </el-popover>
                          <span v-else>--</span>
                        </template>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="content-r">
                    <div class="content-r-l">
                      <div class="top">合同状态</div>
                      <!-- <el-tag v-if="data.signState=='UNSIGNED'" size="mini" type="danger">无合同</el-tag>
                    <el-tag v-if="data.signState=='NORMAL'" size="mini" type="success">正常</el-tag>
                    <el-tag v-if="data.signState=='CANCELLED'" size="mini" type="warning">解除</el-tag> -->
                      <div class="bottom" v-if="data.signState=='NORMAL'" style="color:#0AC258;">正常</div>
                      <div class="bottom" v-else-if="data.signState=='UNSIGNED'" style="color:#CCCCCC;">无合同</div>
                      <div class="bottom" v-else-if="data.signState=='AAA'" style="color:#0d72ff;">AAA</div>
                      <div class="bottom" v-else-if="data.signState=='CANCELLED'" style="color:#FF0D0D;">解除</div>
                      <div class="bottom" v-else-if="data.signState=='EXPIRED'" style="color:#E6A23C;">到期</div>
                      <div class="bottom" v-else>--</div>
                    </div>
                    <div class="content-r-r">
                      <div class="top">预存余额</div>
                      <div class="bottom">{{data.balance}}元</div>
                    </div>
                  </div>
                </el-col>
              </el-row>

            </div>
          </div>
          <div class="left2">
            <div class="title">签约信息</div>
            <div class="content">
              <el-descriptions :column="3">
                <el-descriptions-item label="关联公司"> <template>
                    <div v-if="data.relatedCompanys.length">
                      <p v-for="(item,index) in data.relatedCompanys" :key="index">
                        {{item.name}}
                      </p>
                    </div>
                    <div v-else>--</div>

                  </template></el-descriptions-item>
                <el-descriptions-item label="公司流水">{{data.scale||'--'}}</el-descriptions-item>
                <el-descriptions-item label="服务合同">
                  <pdfPreview :pdfUrls="data.contractUrls"></pdfPreview>
                </el-descriptions-item>
                <el-descriptions-item label="主营业务">
                  <template>
                    <div v-if="data.businesses.length">
                      <span v-for="(item,index) in data.businesses" :key="item.businessId">{{item.name}}<span v-if="(index+1) < data.businesses.length">、</span></span>
                    </div>
                    <span v-else>--</span>

                  </template>
                </el-descriptions-item>
                <el-descriptions-item label="主做平台">
                  <div v-if="data.platforms.length">
                    <span v-for="(item,index) in data.platforms" :key="item.platformId">{{item.name}}<span v-if="(index+1) < data.platforms.length">、</span></span>
                  </div>
                  <span v-else>--</span>

                  <!-- <template v-for="item in data.platforms">
                    {{item.name}}
                  </template> -->
                </el-descriptions-item>
                <el-descriptions-item label="营业执照">
                  <imagePreview v-if="data.licenseUrl" :imageUrls="data.licenseUrl" />
                  <span v-else>--</span>
                </el-descriptions-item>
                <el-descriptions-item label="录入时间">
                  <span v-if="data.createdAt">{{data.createdAt|timeFormat}}</span>
                  <span v-else>--</span>
                </el-descriptions-item>
                <el-descriptions-item label="案件数量">{{data.caseCount||'--'}}</el-descriptions-item>
                <el-descriptions-item label="公司照片">
                  <imagePreview v-if="data.imageUrls" :imageUrls="data.imageUrls" />
                  <span v-else>--</span>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <div class="title" style="font-size:14px;">客户联系人情况</div>
            <div class="content">
              <el-descriptions :column="3">
                <template v-for="(item,index) in data.contacts">
                  <el-descriptions-item label="姓名" :key="index">
                    {{item.name}}（{{item.position}}）
                  </el-descriptions-item>
                  <el-descriptions-item label="微信号" :key="index">
                    {{item.phone}}
                  </el-descriptions-item>
                  <el-descriptions-item label="手机号" :key="index">
                    {{item.wx}}
                  </el-descriptions-item>
                </template>

                <el-descriptions-item label="最近动态">
                  {{data.lastTrendAt|timeFormat}}
                </el-descriptions-item>
                <el-descriptions-item label="客户情况" :span="2">
                  {{data.situation}}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div class="cost">
            <div class="title">
              <span class="left">总收费：{{sum}}元 ｜ 实收服务费:
                <span class="primary">{{allsum}}元</span>
              </span>

              <el-button type="primary" size="small" v-if="['ADMIN','SUPER','LEGAL'].includes(user.role)" @click="addReceivableForm">添加收款项</el-button>

            </div>

            <div class="content">
              <el-table :data="receivableList" size="mini" style="width: 100%;">
                <el-table-column label="收费规则" prop="label" show-overflow-tooltip>
                  <template slot-scope="scope">{{ scope.row.label }}</template>
                </el-table-column>

                <el-table-column label="日期" prop="deadline" show-overflow-tooltip>
                  <template slot-scope="scope">{{ scope.row.deadline|timeFormat }}</template>
                </el-table-column>

                <el-table-column label="金额" prop="amount" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="primary">¥{{ scope.row.amount }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="是否到账" prop="received" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="scope.row.received" style="display: flex;	align-items: center;">
                      <i class="el-icon-ali-dian1" style="color:#0AC258;"></i>
                      <span style="color:#262626;">已到账</span>
                    </span>
                    <span v-else style="display: flex;	align-items: center;">
                      <i class="el-icon-ali-dian1" style="color:#FF0D0D;"></i>
                      <span style="color:#262626;">未到账</span>
                    </span>
                  </template>
                </el-table-column>

                <el-table-column label="操作" width="120" :resizable="false" v-if="['ADMIN','SUPER','LEGAL'].includes(user.role)">
                  <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="()=>revisionReceivableForm(scope.row)">编辑</el-button>
                    <el-button type="text" size="mini" @click="()=>deleteReceivable(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
                <!-- <el-table-column :label="allsum+''">

          </el-table-column> -->

              </el-table>
            </div>

          </div>
        </el-col>
        <el-col :span="6">
          <deposit @success="getCompany" />
          <DynamicStateList style="margin-top:24px;" :list="trendList" @add="addTrendForm" title="动态看板" hidden />
        </el-col>
      </el-row>
    </div>

    <!-- <el-row style="margin-top:20px;" :gutter="20">
      <el-col :span="8">
        <el-descriptions :labelStyle="{width:'75px'}" :column="1" size="mini" border>
          <el-descriptions-item label="公司全称">{{data.name}}</el-descriptions-item>
          <el-descriptions-item label="关联公司">
            <template>
              <div v-if="data.relatedCompanys.length">
                <p v-for="(item,index) in data.relatedCompanys" :key="index">
                  {{item.name}}
                </p>
              </div>
              <div v-else>--</div>

            </template>
          </el-descriptions-item>
          <el-descriptions-item label="签约时间">{{data.signedAt|timeFormat}}</el-descriptions-item>
          <el-descriptions-item label="合同到期时间">{{data.expiredAt|timeFormat}}</el-descriptions-item>
          <el-descriptions-item label="预存余额">{{data.balance}}</el-descriptions-item>
          <el-descriptions-item label="录入时间">{{data.createdAt|timeFormat}}</el-descriptions-item>
          <el-descriptions-item label="动态更新时间">{{data.lastTrendAt|timeFormat}}</el-descriptions-item>
          <el-descriptions-item label="服务合同留底">
            <pdfPreview :pdfUrls="data.contractUrls"></pdfPreview>
          </el-descriptions-item>
          <el-descriptions-item label="合同状态">
            <el-tag v-if="data.signState=='UNSIGNED'" size="mini" type="danger">无合同</el-tag>
            <el-tag v-if="data.signState=='NORMAL'" size="mini" type="success">正常</el-tag>
            <el-tag v-if="data.signState=='CANCELLED'" size="mini" type="warning">解除</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="营业执照">
            <imagePreview v-if="data.licenseUrl" :imageUrls="data.licenseUrl" />
            <span v-else>--</span>
          </el-descriptions-item>
          <el-descriptions-item label="公司照片">
            <imagePreview v-if="data.imageUrls" :imageUrls="data.imageUrls" />
            <span v-else>--</span>
          </el-descriptions-item>
          <el-descriptions-item label="谁签约的">{{data.signerName}}</el-descriptions-item>
          <el-descriptions-item label="主营业务">
            <template v-for="item in data.businesses">
              {{item.name}}
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="主做平台">
            <template v-for="item in data.platforms">
              {{item.name}}
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="公司流水">{{data.scale}}</el-descriptions-item>
          <el-descriptions-item label="对接法务">{{data.legalName}}</el-descriptions-item>
          <el-descriptions-item label="对接群名">{{data.groupName}}</el-descriptions-item>
          <el-descriptions-item label="分账明细">
            <template v-for="item in data.partners">
              <p :key="item.uuid">{{item.accountName}} {{item.ratio}}%</p>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="案件数量">{{data.caseCount}}</el-descriptions-item>
          <el-descriptions-item label="客户联络人">
            <template v-for="item in data.contacts">
              <p :key="item.uuid">{{item.name}} 电话{{item.phone}} 微信{{item.wx}} {{item.position}}</p>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="客户基本情况">{{data.situation}}</el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="16">
        <el-table :data="receivableList" size="mini" border stripe tooltip-effect="dark" style="width: 100%;">
          <el-table-column label="总收费">
            <el-table-column label="收费规则" prop="label" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.label }}</template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="sum+''">
            <el-table-column label="日期" prop="deadline" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.deadline|timeFormat }}</template>
            </el-table-column>

          </el-table-column>
          <el-table-column label="实收服务费">
            <el-table-column label="金额" prop="amount" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.amount }}</template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="allsum+''">
            <el-table-column label="到账否" prop="received" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.received" size="mini" type="success">已到账</el-tag>
                <el-tag v-else size="mini" type="warning">未到账</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60" :resizable="false" v-if="user.role==='ADMIN'">
              <template slot-scope="scope">
                <el-button type="text" size="mini" icon="el-icon-edit" @click="()=>revisionReceivableForm(scope.row)"></el-button>
                <el-button type="text" size="mini" icon="el-icon-delete" @click="()=>deleteReceivable(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table-column>

        </el-table>
        <div style="margin:20px 0;float:right;">
          <el-button icon="el-icon-plus" size="mini" @click="addTrendForm">添加动态</el-button>
          <el-button icon="el-icon-plus" size="mini" v-if="user.role==='ADMIN'" @click="addReceivableForm">添加收款项</el-button>
        </div>
        <DynamicStateList :list="trendList" style="margin-top:20px;" hidden />
      </el-col>
    </el-row> -->
    <receivableForm @success="getReceivableList" ref="receivableForm" />
    <trendForm @success="getTrendList" ref="trendForm" />
    <uploadDialog ref="addLicenseUrl" @success="addLicenseUrlSuccess" />
    <add ref='add' @success="getCompany" />
  </div>
</template>
 <script>
import { mapState } from "vuex";
import DynamicStateList from "@/components/DynamicStateList"
import uploadDialog from "@/components/uploadDialog"
import receivableForm from "@/views/CustomerManagement/components/receivableForm"
import trendForm from "@/views/CustomerManagement/components/trendForm"
import imagePreview from "@/components/imagePreview"
import pdfPreview from "@/components/pdfPreview"
import add from "@/views/CustomerManagement/components/add"
import deposit from "@/views/CustomerManagement/components/deposit"
export default {
  components: {
    DynamicStateList,
    receivableForm,
    trendForm,
    uploadDialog,
    imagePreview,
    add,
    pdfPreview,
    deposit
  },
  data () {
    return {
      data: {
        areaCode: "",
        areaName: "",
        balance: 0,
        businesses: [],
        caseCount: 0,
        contacts: [],
        createdAt: 0,
        expiredAt: null,
        groupName: null,
        lastTrendAt: null,
        legalName: "",
        name: "",
        partners: [],
        platforms: [],
        relatedCompanys: [],
        scale: "",
        scaleId: 5,
        signState: null,
        signedAt: 0,
        signerName: "",
        situation: null,
        updatedAt: 0,
        uuid: "",
        licenseUrl: ''
      },
      receivableList: [],
      trendList: []
    };
  },
  computed: {
    sum () {
      let s = 0;
      this.receivableList.forEach(val => s += val.amount, 0)
      return s
    },
    allsum () {
      let s = 0;
      this.receivableList.forEach(val => val.received && (s += val.amount), 0)
      return s
    },
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {

  },

  methods: {
    // 公司详情
    async getCompany () {
      const { status, data, info } = await this.$api.getCompany(this.$route.params);
      if (status == 200) {
        this.data = data;
      } else {
        this.$message({
          message: info,
          type: 'error'
        })
      }
    },
    async getReceivableList () {
      const { status, data } = await this.$api.getReceivableList({ companyUuid: this.$route.params.uuid });
      if (status === 200) {
        this.receivableList = data;
      }
    },
    async getTrendList () {
      const { status, data } = await this.$api.getTrendList({ companyUuid: this.$route.params.uuid });
      if (status === 200) {
        this.trendList = data.records;
      }
    },
    //修改应收款项
    revisionReceivableForm (data) {
      this.$refs.receivableForm.show({ type: 'revision', data });
    },
    // 添加应收款项
    addReceivableForm () {
      this.$refs.receivableForm.show({ type: 'add' })
    },
    //删除应收款项
    async deleteReceivable ({ uuid, label }) {
      try {
        await this.$confirm(`此操作将永久删除收款项《${label}》, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteReceivable({ uuid })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getReceivableList()
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    addTrendForm () {
      this.$refs.trendForm.show()
    },
    // 上传营业执照
    addLicenseUrl () {
      this.$refs.addLicenseUrl.show();
    },
    // 上传营业执照成功
    addLicenseUrlSuccess (res) {
      console.log(res)
    },
    // 编辑公司
    revision () {
      const platformIds = this.data.platforms.map(item => item.platformId)
      const businessIds = this.data.businesses.map(item => item.businessId)
      this.$refs.add.show({ data: { ...this.data, platformIds, businessIds }, type: 'revision' })
    }
  },
  watch: {
    "$route.params": {
      handler () {
        this.$nextTick(() => {
          this.getCompany();
          this.getReceivableList();
          this.getTrendList()
        })
      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
.CustomerManagementDetail {
  /deep/.DynamicStateList {
    .content {
      height: 460px;
    }
  }
  .left1 {
    background: #fff;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 16px;
      font-weight: 400;
      font-size: 18px;
      .btn {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #0d72ff;
      }
    }
    .content {
      padding: 10px 0;
      &-l {
        padding: 0 16px;
      }
      &-r {
        padding: 0 16px;
        display: flex;
        justify-content: flex-end;
        .top {
          font-size: 14px;
          line-height: 21px;
          color: #666666;
          text-align: right;
        }
        .bottom {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #0d72ff;
          text-align: right;
        }
        &-l {
          margin-right: 40px;
        }
      }
    }
  }
  .left2 {
    margin-top: 24px;
    background: #fff;
    .title {
      line-height: 56px;
      padding: 0 16px;
      font-size: 16px;
    }
    .content {
      padding: 10px 16px;
    }
  }
  .cost {
    margin-top: 24px;
    background: #fff;
    .content {
      padding: 0 16px 20px 16px;
    }
    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      height: 56px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn {
        font-size: 14px;
      }
    }
  }
}
</style>